<template xmlns:b-row="http://www.w3.org/1999/html">
  <section id="agendador">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Editar Consultor"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >

          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner />
          </div>
          <div v-else>
            <validation-observer ref="rulesConsultant">

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="consultant.name"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="E-mail"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="email"
                        v-model="consultant.email"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Senha(Só preeencha se deseja alterar)"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-input
                        id="password"
                        v-model="consultant.password"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>

              <div class="mt-3 d-flex justify-content-start">
                <h3>
                  Eventos
                </h3>
                <b-button
                  class="ml-2"
                  :disabled="isSubmiting"
                  @click="newEvent"
                >
                  Novo evento
                </b-button>
              </div>

              <b-row
                v-for="(event, eventIndex) in consultant.events"
                :key="event.id"
              >
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome"
                      label-for="password"
                    >
                      <b-form-input
                        id="name"
                        v-model="event.name"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="Descrição"
                      label-for="description"
                    >
                      <b-form-textarea
                        id="description"
                        v-model="event.description"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="Slug"
                      label-for="slug"
                    >
                      <b-form-input
                        id="slug"
                        v-model="event.slug"
                        :disabled="isSubmiting"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group>
                    <b-button
                      :disabled="isSubmiting"
                      @click="removeEvent(eventIndex)"
                    >
                      Remover evento
                    </b-button>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <h4>Horários</h4>
                  <div
                    v-for="(workTime, workTimeIndex) in event.workTimes"
                    :key="workTime.id"
                  >
                    <b-row>
                      <b-col cols="2">

                        <b-form-group
                          label="Dia da semana"
                        >
                          <b-form-select
                            v-model="workTime.day_of_week"
                            :options="daysOfWeek"
                            :disabled="isSubmiting"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col cols="2">
                        <b-form-group
                          label="De"
                        >
                          <b-form-input
                            v-model="workTime.start_at"
                            :disabled="isSubmiting"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="2">
                        <b-form-group
                          label="Até"
                        >
                          <b-form-input
                            v-model="workTime.end_at"
                            :disabled="isSubmiting"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="2">
                        <b-form-group
                          label="Intervalo"
                        >
                          <b-form-input
                            v-model="workTime.interval"
                            :disabled="isSubmiting"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="2">
                        <b-button
                          class="mt-2"
                          :disabled="isSubmiting"
                          @click="removeWorkTime(eventIndex, workTimeIndex)"
                        >
                          Remover
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>

                  <b-button
                    class="mr-1"
                    :disabled="isSubmiting"
                    @click="newWorkTime(event.id)"
                  >
                    Adicionar horário
                  </b-button>

                  <hr class="mt-3 mb-3" />
                </b-col>
              </b-row>
              <span></span>
            </validation-observer>

            <b-button
              :disabled="isSubmiting"
              @click="saveConsultant"
            >
              Salvar Tudo
            </b-button>
          </div>

        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BSpinner,
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SchedulerService from '@/services/schedulerService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toasts from '@/mixins/toast'
import requestErrorMessage from '@/mixins/requestErrorMessage'

export default {
  components: {
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BCardActions,
    BRow,
    BCol,
    BBreadcrumb,
  },
  mixins: [toasts, requestErrorMessage],

  props: {
    managerIdFromProps: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Agendador',
          to: { name: 'agendador-user-list' },
        },
        {
          text: 'Editar Consultor',
          active: true,
        },
      ],
      daysOfWeek: [
        {
          text: 'Domingo',
          value: '0value',
        },
        {
          text: 'Segunda',
          value: '1',
        },
        {
          text: 'Terça',
          value: '2',
        },
        {
          text: 'Quarta',
          value: '3',
        },
        {
          text: 'Quinta',
          value: '4',
        },
        {
          text: 'Sexta',
          value: '5',
        },
        {
          text: 'Sábado',
          value: '6',
        },
      ],
      isSubmiting: false,
      isLoading: false,
      consultant: {},
      results: [],
      userData: null,
    }
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.getConsultant()
  },

  methods: {
    removeWorkTime(eventIndex, workTimeIndex) {
      this.consultant.events[eventIndex].workTimes.splice(workTimeIndex, 1)
    },

    newWorkTime(eventId) {
      const event = this.consultant.events.find(f => f.id === eventId)
      event.workTimes.push({
        id: 0,
        day_of_week: '1',
        start_at: '00:00:00',
        end_at: '00:00:00',
        interval: 15,
      })
    },

    removeEvent(eventIndex) {
      this.consultant.events.splice(eventIndex, 1)
    },

    newEvent() {
      this.consultant.events.push({
        id: 0,
        user_id: this.consultant.id,
        name: '',
        description: '',
        slug: '',
        workTimes: [],
      })
    },

    saveConsultant() {
      return this.$refs.rulesConsultant.validate().then(success => {
        if (success) {
          this.isSubmiting = true
          SchedulerService.postConsultant(this.consultant).then(response => {
            if (response.data.data.success) {
              this.toastSuccess('Sucesso!', 'Consultor, evento e horários alterados!')
            } else {
              this.toastDanger('Erro', 'Aconteceu um erro ao salvar os dados')
            }
          }).catch(error => {
            this.toastDanger('Erro!', this.getResponseErrorMessage(error.response.data))
          }).finally(() => {
            this.isSubmiting = false
          })
        } else {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    getConsultant() {
      if (this.$route.params.id !== 0) {
        this.isLoading = true
        SchedulerService.getConsultant(this.$route.params.id).then(response => {
          if (response.data !== null) {
            this.consultant = response.data.data.consultant
            this.consultant.events = this.consultant.events.map(m => ({
              ...m,
              string: m.string.split('\n').join('<BR>'),
            }))
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
  },
}
</script>
